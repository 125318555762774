<template>
	<div class="task">
		<div class="home_center">

			<div class="">
				<div class="center_left">
					<!-- <div class="left_user">
						<img src="../../assets/img/user1.png" class="user_img" alt="">
						<div class="user_right">
							<div class="right_name">
								猿渡健介
							</div>
							<div class="right_msg">
								项目管理
							</div>
							<div class="right_msg">
								東京 / 日本
							</div>
						</div>
					</div> -->
					<el-button type="primary" :class="showIndex==1?'active':'btn'" @click="showIndex=1"><!-- 雇员查看 -->{{$t('task.GYCK')}}</el-button>
					<el-button type="primary"  :class="showIndex==2?'active':'btn'" @click="showIndex=2"><!-- 进度查看 -->{{$t('task.JDCK')}}</el-button>
					<el-button type="primary"  :class="showIndex==3?'active':'btn'" @click="showIndex=3"><!-- 工作评价 -->{{$t('task.GZPJ')}}</el-button>
					<div class="left_tui">
						<div class="tui_title">
							<!-- 工作信息 -->{{$t('task.GZXX')}}
						</div>
						<div class="tui_box">
							<div class="tui_list">
								<!-- 工作名称 -->{{$t('task.GZMC')}}：{{jobInfo.job_name}}
							</div>
							<!-- <div class="tui_list">
								{{$t('GZBH')}}：阿里巴巴
							</div> -->
							<div class="tui_list">
								<!-- 发起时间 -->{{$t('task.FQSJ')}}：{{$util.rTime(jobInfo.update_time)}}
							</div>
							<div class="tui_list">
								<!-- 工作时间 -->{{$t('job.GZSJ')}}：{{jobInfo.oper_time_val}}{{$util.getUnitVal(operTimeMethod,jobInfo.oper_time_method)}}
								<!-- <template v-if="jobInfo.oper_time_method==0">
									{{jobInfo.oper_time_val}}{{$t('TIAN')}}
								</template>
								<template v-if="jobInfo.oper_time_method==1">
									{{jobInfo.oper_time_val}}
								</template>
								<template v-if="jobInfo.oper_time_method==3">
									{{$util.getUnitVal(operTimeMethod,jobInfo.oper_time_method)}}
								</template> -->
							</div>
							<div class="tui_list">
								<!-- 需求人数 -->{{$t('task.XQRS')}}：{{jobInfo.job_num_people}}人
							</div>
							<div class="tui_list">
								<!-- 工作佣金 -->{{$t('task.GZYJ')}}：{{jobInfo.salary_low}} - {{jobInfo.salary_high}} {{jobInfo.salary_unit}}
							</div>
							<!-- <div class="tui_list">
								证书要求：{{jobInfo.job_num_people}}
							</div> -->
							<div class="tui_list" v-if="jobInfo.skills">
								<!-- 技能要求 -->{{$t('task.ZNYQ')}}：{{jobInfo.skills.join(',')}}
							</div>
							<router-link :to='"/work_edit?job_id="+listData.job_id'>
								<el-button type="primary" class="tui_btn"><!-- 编辑工作 -->{{$t('task.BJGZ')}}</el-button>
							</router-link>
							<el-button type="primary" class="tui_btn" @click="del"><!-- 删除 -->{{$t('activity.SC')}}</el-button>
						</div>
						<div class="tui_title">
							<!-- 工作信息 -->{{$t('task.GZXX')}}
						</div>
						<div class="tui_box">
							
							<div class="tui_list">
								{{jobInfo.job_desc}}
							</div>
						</div>
					</div>
					
				</div>
				
			</div>


			<div class="center_people">
				<template v-if="showIndex==1">
				<div class="menu_box">
					<el-badge :value="counts.applyCount" :hidden="counts.applyCount==0" class="item">
					  <div class="menu" :class="{active:listData.apply_status==0}" @click="listData.apply_status=0"><!-- 申请者 -->{{$t('task.SQZ')}}</div>
					</el-badge>
					<el-badge :value="counts.faceCount" :hidden="counts.faceCount==0" class="item">
					  <div class="menu" :class="{active:listData.apply_status==1}" @click="listData.apply_status=1"><!-- 面谈中 -->{{$t('task.MTZ')}}</div>
					</el-badge>
					<el-badge :value="counts.rejectedCount" :hidden="counts.rejectedCount==0" class="item">
					  <div class="menu" :class="{active:listData.apply_status==2}" @click="listData.apply_status=2"><!-- 已拒绝 -->{{$t('task.YJJ')}}</div>
					</el-badge>
					<el-badge :value="counts.passedCount" :hidden="counts.passedCount==0" class="item">
					  <div class="menu" :class="{active:listData.apply_status==3}" @click="listData.apply_status=3"><!-- 已通过 -->{{$t('task.YTG')}}</div>
					</el-badge>
					
				</div>
				<div class="people_list">
					<div class="list_box" v-for="(item,index) in list" :key="item.job_apply_id">
						<img :src="item.apply_avatar_url" class="list_img" alt="">
						<div class="list_text">
							<div class="text_name">
								{{item.apply_open_name}}
								<span>{{$util.rTime(item.create_time)}}</span>
							</div>
							<div class="text_msg">
								{{item.apply_description}}
							</div>
						</div>
						<div class="btn_right">
							<!-- <el-button type="primary" class="btn2">关注</el-button> -->
							 <el-select v-model="item.apply_status" v-if="item.apply_status<2" :placeholder="$t('QXZ')" @change="change_status(item.apply_status,item.job_apply_id,item.apply_user_id,item.apply_user_role)">
							    <el-option v-for="item in applyStatus" :key="item.data_code"  :label="item.data_value" :value="item.data_code/1">
							    </el-option>
							  </el-select>
							<el-button type="primary" class="btn1" @click="senmsg(item.apply_user_id)"><!-- 发消息 -->{{$t('home.FDXX')}}</el-button>
							<!-- <el-button type="primary" class="btn3">已关注</el-button> -->
							<!-- <img src="../../assets/img/more.png" class="more" alt=""> -->
						</div>
					</div>
					
					<!-- <div class="list_none" v-if="list.length==0">
						{{$t('NOMORE')}}
					</div> -->
				</div>
				</template>
				<!-- 进度查看 -->
				<div class="task_list" v-if="showIndex==2">
					<template v-for="item in list" >
						<div class="list_box" v-if="item.job_progress>2">
							<div class="box_user">
								<div class="user_name">
									<img :src="item.employee_avatar_url" class="user" alt="">
									{{item.employee_name}}
								</div>
								<div class="user_right">
									{{$util.rTime(item.close_date)}}  <!-- 完成结算工作 -->{{$t('task.WCJSGZ')}}
								</div>
							</div>
						</div>
						<div class="list_box" v-if="item.job_progress>1">
							<div class="box_user">
								<div class="user_name">
									<img :src="item.employee_avatar_url" class="user" alt="">
									{{item.employee_name}}
								</div>
								<div class="user_right">
									{{$util.rTime(item.submit_date)}}  <!-- 交付工作 -->{{$t('work.JFGZ')}}
								</div>
							</div>
						</div>
						<div class="list_box" v-for="file in item.submit_list" :key="file.job_submit_id">
							<div class="box_user">
								<div class="user_name">
									<img :src="item.employee_avatar_url" class="user" alt="">
									{{item.employee_name}}
								</div>
								<el-button @click="senmsg(item.employee_id)">
									<!-- 发送消息 -->{{$t('FSXX')}}
								</el-button>
							</div>
							<div class="box_form" >
								<div class="flex-start">
									<div class="list_name" style="padding-top:10px;">
										<!-- 文件上传 -->{{$t('job_detail.WJSC')}}
									</div>
									<div class="flex-item">
										<div class="flex-center filerow" v-for="filepage in file.submit_files" :key="filepage.url">
											<div class="flex-item filename">
												{{filepage.name}}
											</div>
											<div style="margin-left: 10px;">
												<el-button type="primary" @click="$util.downFileUrl(filepage.url,filepage.name)"><!-- 点击下载 -->{{$t('job_detail.DJXZ')}}</el-button>
											</div>
										</div>
									</div>
								</div>
								
								<div class="form_list">
									<div class="list_name">
										<!-- 交付说明 -->{{$t('job_detail.JFSM')}}
									</div>
									<div class="list_center">
										{{file.submit_description}}
									</div>
									
								</div>
								<div class="form_list">
									<div class="list_name">
										<!-- 交付时间 -->{{$t('job_detail.JFSJ')}}
									</div>
									<div class="list_center">
										{{$util.rTime(file.submit_time)}}
									</div>
									
								</div>
							</div>
						</div>
					</template>
				</div>
				<!-- 工作评价 -->
				<div class="task_list" v-if="showIndex==3">
					<div class="list_box" v-for="item in list" :key="item.job_comment_id">
						<div class="box_user">
							<div class="user_name">
								<img :src="item.employee_avatar_url" class="user" alt="">
								{{item.employee_name}}
							</div>
							<el-button @click="senmsg(item.employer_id)"><!-- 发送消息 -->{{$t('FSXX')}}</el-button>
						</div>
						<div class="box_form">
							<div class="form_time">
								
								<div><!-- 评分 -->{{$t('QY.PF')}}</div>
								<el-rate v-model="item.employee_score" disabled void-color="#666" disabled-void-color="#666" :colors="colors"></el-rate><span style="color:#FFA034">{{item.employee_score}}<!-- 分 --><!-- {{$t('FEN')}} --></span> <span class="zdpf" v-if="item.is_auto==1"><!-- 自动评分 -->{{$t('task.ZDPF')}}</span>
								<div class="flex-item"></div>
								<div class="time">
									{{item.employee_comment_time}}
								</div>
							</div>
							<div class="form_msg">
								{{item.employee_content}}
							</div>
						</div>
					</div>
					
				</div>
				
			</div>
			
			
			
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	export default {
		data() {
			return {
				showIndex:1,
				jobInfo:{},
				loading:false,
				list:[],
				isLast:0,
				listData:{
					currentPage: 1,
					pageSize: 15,
					job_id: '',
					apply_status: 0
				},
				applyStatus:[],
				speedList:[],
				colors:['#567BB6', '#567BB6', '#567BB6'],
				operTimeMethod:[],
				counts:{}
			};
		},
		components: {
			Homeright,
		},
		created() {
			
			this.listData.job_id=this.$route.query.job_id;
			this.getDetail();
			this.getList();
			this.getUnit();
		},
		watch:{
			'listData.apply_status':{
				handler:function(){
					this.getList('reload');
				}				
			},
			showIndex:{
				handler:function(){
					if(this.showIndex==1){
						this.getList('reload');
					}else{
						this.getList2();
					}
				}				
			}
		},
		methods:{
			senmsg(id){
				this.$emit('chat',{user_id:id});
			},
			//删除
			async del(){
				this.$confirm(this.$t('QDSCM')+'?', this.$t('TISHI'), {
				  confirmButtonText: this.$t('CONFIRM'),
				  cancelButtonText: this.$t('CANCEL'),
				  type: 'warning'
				}).then(async () => {
					let res=await this.$request.get('/api/job/deleteJob',{job_id:this.listData.job_id});
				  this.$message({
					type: 'success',
					message: res.message
				  });
				  this.$router.go(-1);
				}).catch(() => {
				           
				});
			},
			//改变审核状态
			async change_status(status,id,user_id,user_role){
				let res=await this.$request.post('/api/job/updateApplyStatus',{job_apply_id:id,apply_status:status,apply_user_id:user_id,apply_user_role:user_role});
				this.getList('reload');
				this.getDetail();
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');				
				this.applyStatus=res.data.apply_status.data;
				this.operTimeMethod=res.data.oper_time_method.data;
			},
			async getDetail(){
				let res=await this.$request.get('/api/job/getJob',{job_id:this.listData.job_id});
				this.jobInfo=res.data;
				let res2=await this.$request.get('/api/job/applySearchStat',{job_id:this.listData.job_id});
				this.counts=res2.data;
			},
			//获取列表
			async getList2(){
				this.loading=true;
				let urls={1:'/api/job/applyPage',2:'/api/job/getSpeedList',3:'/api/job/getCommentList'};
				let res=await this.$request.get(urls[this.showIndex],{job_id:this.listData.job_id});
				this.loading=false;
				this.list=res.data.records;
				
			},
			//获取列表
			  async getList(t){
				let urls={1:'/api/job/applyPage',2:'/api/job/getSpeedList',3:'/api/job/getCommentList'};
				if(t=='reload'){
					this.listData.currentPage=1;
					this.isLast=0;			  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				this.loading=true;
				let res=await this.$request.post('/api/job/applyPage',this.listData);
				this.loading=false;
				
				if(t=='reload'){
					this.list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.list=[...this.list,...res.data.records];
					}
				}
				if(this.listData.currentPage>=res.data.totalPage){
					this.isLast=1;
				}else{
					this.listData.currentPage+=1;
				}
				
			  },
			
		}
	};
</script>

<style lang="less" scoped>
	.task{
		min-height: 100%;
		background-color: #212121;

		.center_left {
			width: 286px;
			min-height: 233px;
			// background: #212121;
			border-radius: 3px;
			color:#fff;
			// border: 2px solid #567BB6;
			// padding: 30px;
			// box-sizing: border-box;
			.btn{
				width: 286px;
				height: 51px;
				background: #212121;
				border-radius: 7px;
				border: 1px solid #567BB6;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				box-sizing: border-box;
				margin-bottom: 10px;
			}
			.btn:hover{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 10px;
			}
			.active{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 10px;
			}
			.el-button{
				margin-left: 0;
			}
			.left_user{
				width: 286px;
				min-height: 169px;
				border-radius: 7px;
				border: 1px solid #567BB6;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				.user_img{
					width: 103px;
					height: 103px;
					margin-right: 25px;
					border-radius: 10px;
				}
				.user_right{
					width: 100px;
					.right_name{
						font-size: 15px;
						font-weight: bold;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.right_msg{
						font-size: 12px;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
			.left_tui{
				font-size: 15px;
				padding: 30px;
				background-color: #567BB6;
				border-radius: 3px;
				.tui_title{
					font-weight: bold;
					margin-bottom: 10px;
				}
				.tui_box{
					padding: 20px 0;
					border-top: 1px solid #FFFFFF;
					line-height: 1.3;
					.tui_list{
						font-size: 12px;
						margin-bottom: 5px;
					}
					.tui_btn{
						width: 208px;
						height: 34px;
						background: #FFFFFF;
						border-radius: 3px;
						border: 1px solid #FFFFFF;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #212121;
						margin-top: 10px;
					}
				}
				.tui_change{
					width: 208px;
					height: 34px;
					border-radius: 3px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #567BB6;
					border: 1px solid #FFFFFF;
				}
				
			}
			
		}

		.home_center {
			display: flex;
			justify-content: flex-end;
		}

		.center_people {
			padding: 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			
			.menu_box{display: flex;align-items: center;width: 878px;height: 69px;background: #000000;border-radius: 7px;padding: 0 30px;margin-bottom: 10px;box-sizing: border-box;color: #fff;
				.menu{font-size: 15px;width: 80px;text-align: center;margin-left: 30px;cursor: pointer;
					&.active{color: #567BB6;position: relative;
						&:before{content:""; width: 43px;height: 3px;background: #567BB6;border-radius: 7px;position: absolute;bottom: -10px;left: 50%;margin-left: -20px;}
					}
				}
				
			}

			.people_list{
				width: 878px;
				box-sizing: border-box;
				padding: 20px;
				background: #000000;
				border-radius: 7px;
				color: #fff;
				margin-bottom: 20px;
				.list_box{
					height: 84px;
					border-bottom: 1px solid #567BB6;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.list_img{
						width: 43px;
						height: 43px;
						object-fit: cover;
						margin-right: 15px;
						border-radius: 5px;
					}
					.list_text{
						width: 500px;
						.text_name{
							display: flex;
							align-items: center;
							font-size: 14px;
							color: #fff;
							margin-bottom: 10px;
							font-weight: bold;
							span{
								font-weight: normal;
								font-size: 12px;
								margin-left: 10px;
							}
						}
						.text_msg{
							font-size: 12px;
							line-height: 1.3;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}
					.btn_right{
						display: flex;
						align-items: center;
						.more{
							width: 27px;
							height: 27px;
							margin-left: 15px;
						}
						.btn1{
							//width: 86px;
							height: 34px;
							background: #567BB6;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: none;
							margin-left: 10px;
						}
						.el-input{
							
						}
						.el-input__inner{
							width: 100px;
							height: 34px;
							border: 1px solid #567BB6;
							background-color: #000;
							color: #fff;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
							// margin-right: 10px;
						}
						.btn2{
							width: 86px;
							height: 34px;
							border: 1px solid #567BB6;
							background-color: #000;
							color: #fff;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 10px;
						}
						.btn3{
							width: 86px;
							height: 34px;
							border: 1px solid #B65664;
							background-color: #000;
							color: #B65664;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 10px;
						}
					}
				}
				.list_none{
					text-align: center;
					font-size: 14px;
					line-height: 40px;
					font-weight: bold;
					color: #fff;
				}
			}

		}
	
		.task_list{
			width: 878px;
			background-color: #000;
			border-radius: 5px;
			.list_box{
				margin:10px;
				background-color: #16171C;
				border-radius: 5px;
				padding: 15px;
				box-sizing: border-box;
				
				color: #fff;
				.box_user{
					display: flex;
					align-items: center;
					justify-content: space-between;
					
					.user{
						width: 43px;
						height: 43px;
						border-radius: 5px;
						margin-right: 20px;
					}
					.user_name{
						width: 580px;
						font-size: 14px;
						display: flex;
						align-items: center;
					}
					.user_right{
						font-size: 12px;
						color: #567BB6;
					}
					.el-button{
						background-color: #567BB6;
						border: 1px solid #567BB6;
						color: #fff;
					}
				}
				.box_form{border-top: 1px solid #000;margin-top: 10px;padding-top: 10px;
					.filerow{margin-top: 10px;
						&:first-child{margin-top: 0;}
						.el-button{
							background-color: #567BB6;
							border: 1px solid #567BB6;
							color: #fff;
						}
					}
					.filename{display: flex;flex-wrap: wrap;word-break: break-all; box-sizing: border-box; min-height: 40px;padding: 10px;border-radius: 4px;line-height: 1.3;border:1px solid #567BB6;font-size: 12px;}
					.list_name{
						width: 120px;
						margin-right: 15px;
						text-align: right;
						font-size: 14px;
					}
					.form_list{
						display: flex;
						align-items: center;
						margin-top: 10px;
						
						.el-input{
							width: 429px;
							margin-right: 10px;
						}
						.el-input__inner{
							
							color: #fff;
							font-size: 12px;
							background-color: #000;
							border: 1px solid #567BB6;
						}
						.list_center{
							width: 429px;
							margin-right: 10px;
							border-bottom: 1px solid #979797;
							color: #fff;
							line-height: 35px;
							font-size: 12px;
						}
						.el-button{
							background-color: #567BB6;
							border: 1px solid #567BB6;
							color: #fff;
						}
					}
					.form_time{
						display: flex;
						align-items: center;
						
						
						.time{
							color: #567BB6;
							font-size: 12px;
						}
					}
					.zdpf{display: inline-flex;align-items: center;height: 20px;background-color: #567BB6;border-radius: 20px;font-size: 12px;color: #fff;padding:0 10px; margin-left: 10px;}
					.form_msg{
						margin-top: 10px;
						color: #fff;
						font-size: 12px;
						line-height: 1.3;
					}
				}
			}
		}
	}
</style>
